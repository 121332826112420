<template>
  <div>
    <h1 :class="$style.pageTitle">ユーザー作成</h1>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="pull-right mt-2">
              <div class="d-inline-block">
                <router-link :to="{ name: 'userStoreForceBulk' }">
                  <a-button type="primary" class="ml-3" autoInsertSpaceInButton="false" :class="$style.filledBtn">一括作成</a-button>
                </router-link>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div>
              <div v-if="loading" class="text-center">
                <a-spin tip="Loading..." />
              </div>
              <a-form-model
                v-if="!loading"
                ref="ruleForm"
                :model="form"
                :rules="rules"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
              >
                <a-form-model-item label="会社" ref="company_id" prop="company_id">
                  <a-select
                    v-model="companyId"
                    show-search
                    @change="changeSelectedCompanyName"
                    :filter-option="filterOption"
                  >
                    <a-select-option
                      v-for="item in companies"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.id + ': ' + item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item ref="name" label="ユーザー名" prop="name">
                  <a-input
                    v-model="form.name"
                    @blur="
                      () => {
                        $refs.name.onFieldBlur()
                      }
                    "
                  />
                </a-form-model-item>
                <a-form-model-item ref="phonetic" label="ふりがな" prop="phonetic">
                  <a-input
                    v-model="form.phonetic"
                    @blur="
                      () => {
                        $refs.phonetic.onFieldBlur()
                      }
                    "
                  />
                </a-form-model-item>
                <a-form-model-item ref="email" label="メールアドレス" prop="email">
                  <a-input
                    v-model="form.email"
                    @blur="
                      () => {
                        $refs.email.onFieldBlur()
                      }
                    "
                  />
                </a-form-model-item>
                <a-form-model-item label="権限" ref="role" prop="role">
                  <a-select v-model="form.role" placeholder="権限を選択してください。">
                    <a-select-option v-for="item in roles" :key="item.value">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>

                <a-form-model-item ref="password" label="パスワード" prop="password">
                  <a-input
                    v-model="form.password"
                    @blur="
                      () => {
                        $refs.password.onFieldBlur()
                      }
                    "
                  />
                </a-form-model-item>

                <a-form-model-item ref="fleet_flag" label="解析対象" prop="fleet_flag" extra="ロガーからのデータはすべて解析されます。">
                  <a-radio-group v-model="form.fleet_flag">
                    <a-radio :value="0">解析対象にする</a-radio>
                    <a-radio :value="1">解析対象にしない</a-radio>
                  </a-radio-group>
                </a-form-model-item>

                <a-form-model-item ref="tel" label="電話番号" prop="tel">
                  <a-input
                    v-model="form.tel"
                    @blur="
                      () => {
                        $refs.tel.onFieldBlur()
                      }
                    "
                  />
                </a-form-model-item>
                <a-form-model-item ref="mobile_model" label="スマホ機種" prop="mobile_model">
                  <a-input
                    v-model="form.mobile_model"
                    @blur="
                      () => {
                        $refs.mobile_model.onFieldBlur()
                      }
                    "
                  />
                </a-form-model-item>
                <a-form-model-item ref="mobile_sim" label="スマホのSIM" prop="mobile_sim">
                  <a-input
                    v-model="form.mobile_sim"
                    @blur="
                      () => {
                        $refs.mobile_sim.onFieldBlur()
                      }
                    "
                  />
                </a-form-model-item>
                <a-form-model-item ref="announcement_flag" label="NOWの使用" prop="announcement_flag">
                  <a-radio-group v-model="form.announcement_flag">
                    <a-radio :value="0">使用しない</a-radio>
                    <a-radio :value="1">使用する</a-radio>
                  </a-radio-group>
                </a-form-model-item>
                <a-form-model-item ref="plus_flag" label="PLUSの使用" prop="plus_flag">
                  <a-radio-group v-model="form.plus_flag">
                    <a-radio :value="0">使用しない</a-radio>
                    <a-radio :value="1">使用する</a-radio>
                  </a-radio-group>
                </a-form-model-item>
                <a-form-model-item ref="interlocking_detector" label="連動検知器の使用" prop="interlocking_detector">
                  <a-radio-group v-model="form.interlocking_detector">
                    <a-radio :value="0">使用しない</a-radio>
                    <a-radio :value="1">使用する</a-radio>
                  </a-radio-group>
                </a-form-model-item>

                <a-form-model-item ref="roll_call_able" label="点呼権限" prop="roll_call_able">
                  <a-radio-group v-model="form.roll_call_able">
                    <a-radio :value="0">権限に従う</a-radio>
                    <a-radio :value="1">支店管理者と同等にする</a-radio>
                  </a-radio-group>
                </a-form-model-item>

                <a-form-model-item ref="create_operator_flag" label="代行オペレーター作成可能" prop="create_operator_flag">
                  <a-radio-group v-model="form.create_operator_flag">
                    <a-radio :value="0">オペレーターの作成は不可</a-radio>
                    <a-radio :value="1">オペレーターの作成を可能</a-radio>
                  </a-radio-group>
                </a-form-model-item>

                <a-form-model-item ref="operator_flag" label="代行オペレーター" prop="operator_flag">
                  <a-radio-group v-model="form.operator_flag">
                    <a-radio :value="0">オペレーターにしない</a-radio>
                    <a-radio :value="1">オペレーターにする</a-radio>
                  </a-radio-group>
                </a-form-model-item>

                <a-form-model-item ref="roll_call_agent" label="点呼代行対象" prop="roll_call_agent">
                  <a-radio-group v-model="form.roll_call_agent">
                    <a-radio :value="0">対象にしない</a-radio>
                    <a-radio :value="1">対象にする</a-radio>
                  </a-radio-group>
                </a-form-model-item>

                <a-config-provider :locale="locale">
                  <a-form-model-item ref="roll_call_date_start" label="点呼代行開始日" prop="roll_call_date_start">
                    <a-date-picker placeholder="点呼代行開始日" v-model="rollCallDateStart" />
                  </a-form-model-item>
                </a-config-provider>

                <a-config-provider :locale="locale">
                  <a-form-model-item ref="roll_call_date_end" label="点呼代行終了日" prop="roll_call_date_end">
                    <a-date-picker placeholder="点呼代行終了日" v-model="rollCallDateEnd" />
                  </a-form-model-item>
                </a-config-provider>

                <a-form-model-item ref="roll_call_count" label="点呼代行回数/月" prop="roll_call_count" extra="点呼代行自体をしない場合は「0」にしておいてください。">
                  <a-input-number v-model="form.roll_call_count" :min="0" />
                </a-form-model-item>

                <a-form-model-item ref="roll_call_limited" label="点呼利用制限" prop="roll_call_limited" extra="点呼代行ではないユーザーには影響しません。">
                  <a-radio-group v-model="form.roll_call_limited">
                    <a-radio :value="0">全ての点呼を代行にする</a-radio>
                    <a-radio :value="1">平日の日中は代行にしない</a-radio>
                    <a-radio :value="2">土日祝は代行にしない</a-radio>
                  </a-radio-group>
                </a-form-model-item>

                <a-form-model-item ref="allow_driving_schedule" label="運行計画表の被閲覧" prop="allow_driving_schedule">
                  <a-radio-group v-model="form.allow_driving_schedule">
                    <a-radio :value="0">許可しない</a-radio>
                    <a-radio :value="1">許可する</a-radio>
                  </a-radio-group>
                </a-form-model-item>

                <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                  <a-button type="primary" @click="onSubmit" :class="$style.filledBtn" :loading="btnLoading">
                    作成
                  </a-button>
                  <router-link :to="backPath">
                    <a-button style="margin-left: 10px;">
                      戻る
                    </a-button>
                  </router-link>
                  <p>※作成されたユーザーに登録完了メールは送信されません。</p>
                </a-form-model-item>
              </a-form-model>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
.list-name {
  font-size: 1.5rem;
}
</style>
<script>

import Vue from 'vue'
import { objectData } from '@/services/object'
import { enumData } from '@/services/enum'
import jaJa from 'ant-design-vue/es/locale/ja_JP'

export default {
  name: 'FormUser',
  data() {
    return {
      locale: jaJa,
      loading: true,
      btnLoading: false,
      backPath: '/company',
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      user: Object.assign({}, objectData.user),
      companyId: 1,
      rollCallDateStart: '',
      rollCallDateEnd: '',
      form: {
        company_id: 1,
        role: undefined,
        name: '',
        phonetic: '',
        tel: '',
        password: '',
        mobile_model: '',
        mobile_sim: '',
        fleet_flag: 0,
        announcement_flag: 0,
        plus_flag: 0,
        interlocking_detector: 0,
        operator_flag: 0,
        roll_call_agent: 0,
        roll_call_count: 0,
        roll_call_limited: 0,
        create_operator_flag: 0,
        roll_call_able: 0,
        allow_driving_schedule: 0,
      },
      roles: enumData.userRole,
      rules: {
        company_id: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        name: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { max: 255, message: '255文字までです。', trigger: 'blur' },
        ],
        phonetic: [
          { max: 255, message: '255文字までです。', trigger: 'blur' },
        ],
        email: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { min: 4, message: '4文字以上入力してください。', trigger: 'blur' },
          { max: 255, message: '255文字までです。', trigger: 'blur' },
        ],
        role: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        fleet_flag: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        tel: [
          { max: 99, message: '99文字までです。', trigger: 'blur' },
        ],
        mobile_model: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { max: 255, message: '255文字までです。', trigger: 'blur' },
        ],
        mobile_sim: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { max: 255, message: '255文字までです。', trigger: 'blur' },
        ],
        announcement_flag: [],
        plus_flag: [],
        interlocking_detector: [],
        operator_flag: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        roll_call_agent: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        roll_call_count: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        roll_call_limited: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        create_operator_flag: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        roll_call_able: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        allow_driving_schedule: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    changeSelectedCompanyName(val) {
      this.companyId = val
      this.form.company_id = val
    },
    onSubmit() {
      this.btnLoading = true
      const _this = this
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.form.roll_call_date_start = this.rollCallDateStart ? this.rollCallDateStart.format('YYYY-MM-DD') : null
          this.form.roll_call_date_end = this.rollCallDateEnd ? this.rollCallDateEnd.format('YYYY-MM-DD') : null
          var formData = this.form
          const send = Vue.prototype.$api.send('post', 'user_store', formData)
          send.then(response => {
            _this.$notification['success']({
              message: this.form.name + ' を作成しました。',
            })
            this.btnLoading = false
          })
            .catch(error => {
              if (error.status === 400 || error.status === 403) {
                _this.$notification['error']({
                  message: error.data.data,
                })
              } else {
                for (var key in error.data.data) {
                  _this.$refs[key].validateState = 'error'
                  _this.$refs[key].validateMessage = error.data.data[key]
                }
              }
              this.btnLoading = false
              console.log(error)
            })
        } else {
          console.log('error submit!!')
          this.btnLoading = false
          return false
        }
      })
    },
  },
  created() {
    const companyList = Vue.prototype.$api.send('get', 'companies')
    companyList.then(response => {
      this.loading = false
      this.companies = response
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 会社の取得に失敗しました。',
        })
        this.loading = false
      })
  },
}
</script>
